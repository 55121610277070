body {
  margin: 0;
  /* background-color: #010810; */
  /* font-family: "Inter", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
   position: absolute;
   z-index: 1;
}

p {
  margin: 0;
  padding: 0;
}
